import ReactPlayer from 'react-player';
import { useInView } from 'react-hook-inview';
import styled, { keyframes, css } from 'styled-components';
import { fadeIn, fadeInUp, fadeInDown, fadeInLeft } from 'react-animations';

import Navigation from 'components/Navigation';
import Heading from 'components/Heading';
import LinkButton from 'components/LinkButton';
import MoreInfo from 'components/_MoreInfo';
import Footer from 'components/_Footer';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInDownAnimation = keyframes`${fadeInDown}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: #a70532;
`;

const LandingContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  :after {
    content: '';
    position: absolute;
    width: 65vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: url('/images/LoadingImage.png');
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 768px) {
    :after {
      width: 100vw;
      background-position: center;
    }
  }
`;

const LandingContentContainer = styled.div`
  width: 50%;
  height: 50%;
  margin-bottom: 50px;
  padding: 0 75px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  div:first-child {
    p:first-child {
      animation: 2s ${fadeInAnimation};
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
    }

    p:last-child {
      animation: 2s ${fadeInUpAnimation};
      animation-delay: 1.5s;
      animation-fill-mode: backwards;
    }
  }

  a {
    margin-top: 25px;
  }

  div:last-child {
    margin-top: auto;
  }

  a,
  div:last-child {
    animation: 2s ${fadeInAnimation};
    animation-delay: 2.5s;
    animation-fill-mode: backwards;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: calc(50% - 75px);
  }
`;

const LandingVideoWrapper = styled.div`
  width: 50%;
  height: 100%;
  max-height: 500px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  video {
    object-fit: cover;
  }

  :after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #a70532;
    mix-blend-mode: screen;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 35%;
    z-index: 1;
    transform: inherit;
    top: 65%;

    :after {
      box-shadow: 0px 0px 50px 50px #a70532;
    }
  }
`;

const HowItWorksContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const HowItWorksContentContainer = styled.div<{ inView: boolean }>`
  width: 50%;
  height: 100%;
  padding: 150px 75px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    color: white;
  }

  div:first-child p:first-child {
    animation: ${({ inView }) => inView && css`2s ${fadeInAnimation}`};
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
  }

  div:first-child p:last-child {
    animation: ${({ inView }) => inView && css`1s ${fadeInLeftAnimation}`};
    animation-delay: 1s;
    animation-fill-mode: backwards;
    animation-timing-function: ease-out;
  }

  div:last-child {
    max-width: 400px;
    margin-left: auto;
    animation: ${({ inView }) => inView && css`2s ${fadeInAnimation}`};
    animation-delay: 1.5s;
    animation-fill-mode: backwards;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    z-index: 2;
  }
`;

const HowItWorksVideoWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: relative;

  video {
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
  }
`;

const OurMissionContainer = styled.div<{ inView: boolean }>`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  position: relative;

  > div:first-child {
    width: 50%;
    height: 100%;
    background-image: url('/images/sam-burriss-jTSf1xnsoCs-unsplash.jpg');
    background-size: cover;
    background-position: center;
  }

  > div:first-child {
    animation: ${({ inView }) => inView && css`1s ${fadeInDownAnimation}`};
  }
  > div:last-child {
    animation: ${({ inView }) => inView && css`1s ${fadeInUpAnimation}`};
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;

    > div:first-child {
      animation: ${({ inView }) => inView && css`1s ${fadeInLeftAnimation}`};
      width: 100% !important;
      height: 50% !important;
    }
  }
`;

const OurMissionContentContainer = styled.div`
  width: 50%;
  height: 100%;
  padding: 150px 75px;
  background-color: #00305f;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    color: white;
  }

  div:last-child {
    max-width: 400px;
    margin-left: auto;

    a {
      margin-top: 50px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100% !important;
    height: 50% !important;
    justify-content: flex-end;
    box-shadow: 0px 0px 50px 50px #00305f;
  }
`;

const Home = () => {
  const [howItWorksRef, howItWorksIsVisible] = useInView();
  const [ourMissionRef, ourMissionIsVisible] = useInView();

  return (
    <Container>
      <Navigation />

      <LandingContainer>
        <LandingVideoWrapper>
          <ReactPlayer
            width="100%"
            height="100%"
            url="/videos/Header_Black_H264_v01.mp4"
            playing
            muted
            loop
          />
        </LandingVideoWrapper>
        <LandingContentContainer>
          <div>
            <Heading type="subtitle" label="Eucerin presents" />
            <Heading type="title" label="The Sound of Skinclusion" />
          </div>
          <LinkButton buttontype="primary" to="/transition" label="Scan your skin" />
          <div>
            <MoreInfo
              onClick={() => {
                const el = document.getElementById('how-it-works');
                el?.scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </div>
        </LandingContentContainer>
      </LandingContainer>

      <HowItWorksContainer id="how-it-works">
        <HowItWorksContentContainer inView={howItWorksIsVisible}>
          <div ref={howItWorksRef}>
            <Heading type="subtitle" label="How it works" />
            <Heading type="title" label="Your skin is the composer" />
          </div>
          <div>
            <p>
              By scanning the surface of your skin, our AI selects the perfect instruments and sound
              effects to complement your song.
            </p>
          </div>
        </HowItWorksContentContainer>
        <HowItWorksVideoWrapper>
          <div
            style={{
              width: '50px',
              height: '50px',
              background: '#fff',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              margin: 'auto',
            }}
          />
          <ReactPlayer
            width="100%"
            height="100%"
            url="/videos/Slider_BG_H264_v01.mp4"
            playing
            muted
            loop
          />
        </HowItWorksVideoWrapper>
      </HowItWorksContainer>

      <OurMissionContainer inView={ourMissionIsVisible}>
        <div />
        <div
          ref={ourMissionRef}
          style={{
            display: 'block',
            marginTop: '1px',
          }}
        />
        <OurMissionContentContainer>
          <div>
            <Heading type="subtitle" label="Our Social Mission" />
            <Heading type="title" label="#skinclusion" />
          </div>
          <div>
            <p>
              Visible skin conditions and diseases are more than just a physical burden. Being
              socially excluded can cause deep psychological and emotional scarring - scars that we
              can only heal if we all stand together against stigmatisation. Join us as we spread
              our message of #skinclusion.
            </p>
            <LinkButton buttontype="secondary" to="/transition" label="Find out more" />
          </div>
        </OurMissionContentContainer>
      </OurMissionContainer>

      <Footer />
    </Container>
  );
};

export default Home;
