import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';
import { useRef, useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import Heading from 'components/Heading';
import Navigation from 'components/Navigation';
import { uploadImage } from 'lib/hautAi';
import useHash from 'hooks/useHash';

const fadeInAnimation = keyframes`${fadeIn}`;

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #223341;
`;

const ToolAnalyseContainer = styled.div<{ animation: boolean }>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  animation: 0.75s ${({ animation }) => animation && fadeInAnimation};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  padding: 141px 30px 56px;

  @media only screen and (min-width: 768px) {
    max-width: 420px;
    margin: 0 auto;
  }
`;

const Controls = styled.div`
  width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 420px;
    margin: 0 auto;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Indicator = styled.div<{ isOk?: boolean }>`
  width: 33%;
  max-width: 125px;
  padding: 12px 18px;
  border-radius: 50px;
  border: 1px solid #fff;

  ${({ isOk }): string => (isOk ? 'background: #fff;' : 'color: #fff; opacity: 0.65;')}

  :not(:first-child):not(:last-child) {
    margin: 0 5px;
  }
`;

const Button = styled.button<{ isEnabled?: boolean }>`
  width: 65px;
  height: 65px;
  border: none;
  border-radius: 50%;
  border: ${({ isEnabled }): string => (isEnabled ? '1px solid #fff' : '')};
  background: rgba(255, 255, 255, 0.35);
  margin-top: 40px;
  position: relative;
  cursor: ${({ isEnabled }): string => (isEnabled ? 'pointer' : 'not-allowed')};

  ${({ isEnabled }) =>
    isEnabled &&
    `
    margin-bottom: 4px;
    
        :after{
            content: "";
            background: #fff;
            width: 45px;
            height: 45px;
            position: absolute;
            left: 9px;
            top: 9px;
            border-radius: 50%;
        }
  `}
`;

const WebcamOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    left: 30px;
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: calc(100% - 60px);
    padding-bottom: calc(100% - 60px);
    box-shadow: 0 0 0 2000px rgb(34 51 65 / 50%);

    @media (min-width: 769px) {
      width: 420px;
      padding-bottom: 420px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
`;

const ToolAnalyse = () => {
  const { encode } = useHash();
  const navigate = useNavigate();
  const webcamRef = useRef<Webcam>(null);

  const [webcamIsActive, setWebcamIsActive] = useState<boolean>(false);
  const [accessToken] = useState<string>('');
  const [companyId] = useState<string>('');
  const [subjectId] = useState<string>('');
  const [batchId] = useState<string>('');
  // const [buttonIsEnabled, setButtonIsEnabled] = useState<boolean>(true);

  const onUserMedia = async () => {
    setWebcamIsActive(true);

    // await login().then(res => {
    //   setAccessToken(res.access_token);
    //   setCompanyId(res.company_id);

    //   createSubject(res.access_token, res.company_id).then(subject => {
    //     setSubjectId(subject.id);
    //     createBatch(res.access_token, res.company_id, subject.id).then(batch =>
    //       setBatchId(batch.id),
    //     );
    //   });
    // });
  };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imgSrc = webcamRef.current.getScreenshot();

      if (accessToken && companyId && subjectId && batchId && imgSrc)
        uploadImage(accessToken, companyId, subjectId, batchId, imgSrc).then(image => {
          const assetId = parseFloat(image.id);
          const hash = encode(assetId);

          navigate(`/share/${hash}`, { replace: true });
        });
    }
  }, [webcamRef, accessToken, companyId, subjectId, batchId]);

  return (
    <Background>
      <Navigation />
      <ToolAnalyseContainer animation={webcamIsActive}>
        {webcamIsActive && (
          <>
            <Content>
              <Heading type="tool-title" label="The AI will begin analysing your face" />
              <Controls>
                <IndicatorContainer>
                  <Indicator isOk>Lighting</Indicator>
                  <Indicator isOk>In frame</Indicator>
                  <Indicator isOk>Focus</Indicator>
                </IndicatorContainer>
                <Button isEnabled onClick={capture} />
              </Controls>
            </Content>
            <WebcamOverlay />
          </>
        )}
        <Webcam
          height="100%"
          mirrored
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: { min: 480 },
            height: { min: 720 },
            facingMode: 'selfie',
          }}
          onUserMedia={onUserMedia}
        />
      </ToolAnalyseContainer>
    </Background>
  );
};

export default ToolAnalyse;
