import { useMemo, useCallback } from 'react';
import Hashids from 'hashids';

const HASH_SALT = process.env.REACT_APP_HASH_SALT;
const HASH_PADDING = Number(process.env.REACT_APP_HASH_PADDING || 10);

const useHash = () => {
  const hashids = useMemo(() => new Hashids(HASH_SALT, HASH_PADDING), []);

  const timestamp = useMemo(() => {
    return Math.floor(new Date().getTime() / 1000);
  }, []);

  const encode = useCallback(
    (assetId: number) => {
      return hashids.encode(assetId, timestamp);
    },
    [hashids, timestamp],
  );

  const decode = useCallback(
    (hash: string) => {
      const decodedHash = hashids.decode(hash);

      if (decodedHash.length !== 2) {
        throw new Error('Hash does not contain required data');
      }

      return { id: decodedHash[0], timestamp: decodedHash[1] };
    },
    [hashids],
  );

  return {
    encode,
    decode,
  };
};

export default useHash;
