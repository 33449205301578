import ToolStart from 'pages/ToolStart';
import ToolFinish from 'pages/ToolFinish';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import PageContainer from 'components/PageContainer';

import ToolAnalyseMobile from 'pages/Mobile/ToolAnalyse';
import ToolAnalyseDesktop from 'pages/Desktop/ToolAnalyse';

import HomeMobile from './pages/Mobile/Home';
import HomeDesktop from './pages/Desktop/Home';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<PageContainer mobile={<HomeMobile />} desktop={<HomeDesktop />} />}
        />

        <Route path="/tool-start" element={<ToolStart />} />

        <Route
          path="/tool-analyse"
          element={
            <PageContainer mobile={<ToolAnalyseMobile />} desktop={<ToolAnalyseDesktop />} />
          }
        />

        <Route path="/tool-finish" element={<ToolFinish />} />

        <Route path="/share/:hash*" element={<ToolFinish />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
