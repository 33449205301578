import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import { useNavigate } from 'react-router-dom';
import Heading from 'components/Heading';
import Navigation from 'components/Navigation';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #223341;
`;

const ToolStartContainer = styled.div`
  height: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    max-width: 420px;
    margin: 0 auto;
  }
`;

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const HeadingWrapper = styled.div`
  animation: 0.75s ${fadeInUpAnimation};
`;

const ToolStart = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/tool-analyse', { replace: true });
    }, 1500);
  }, [navigate]);

  return (
    <Background>
      <Navigation />
      <ToolStartContainer>
        <HeadingWrapper>
          <Heading label="Let's start with your song" type="title" />
        </HeadingWrapper>
      </ToolStartContainer>
    </Background>
  );
};

export default ToolStart;
