import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ButtonProps {
  buttontype: 'primary' | 'secondary' | 'round';
  to?: string | undefined;
  label?: string | React.ReactNode;
  grow?: boolean;
}

const ButtonContainer = styled(Link)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props => (props.buttontype === 'round' ? null : '170px')};
  width: ${props => (props.grow ? '100%' : 'fit-content')};
  height: 50px;
  padding: ${props => (props.buttontype === 'round' ? '18px' : '0 18px')};
  border-radius: 50px;
  background-color: ${props => (props.buttontype === 'primary' ? '#fff' : 'unset')};
  color: ${props => (props.buttontype === 'primary' ? '#A70532' : '#fff')};
  border: ${props => (props.buttontype === 'primary' ? 'unset' : '1px solid #fff')};
  text-decoration: none;
  font-size: 1.125rem;
`;

const Button = ({ buttontype, to, label, grow }: ButtonProps) => {
  return (
    <ButtonContainer to={to ?? ''} buttontype={buttontype} grow={grow}>
      {label}
    </ButtonContainer>
  );
};

export default Button;
