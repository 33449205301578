import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Heading from '../Heading';

const Container = styled.footer`
  width: 100%;
  background-color: #131e28;
  padding: 56px 32px 48px;
`;

const Content = styled.div`
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
  }

  > div:last-child a {
    font-family: Roboto;
    font-size: 14px;

    text-align: center;
    text-decoration-line: underline;

    color: #89bbe5;
  }

  > div:last-child a:first-child {
    margin-top: 64px;
    margin-bottom: 20px;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <div>
          <Heading type="footer" label="The Sound of Skinclusion" />
          <Heading type="subtitle" label="By Eucerin" />
        </div>

        <div>
          <Link to="/">Terms and conditions</Link>
          <Link to="/">Privacy statement</Link>
        </div>
      </Content>
    </Container>
  );
};

export default Footer;
