import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Heading from './Heading';

const FooterContainer = styled.footer`
  width: 100%;
  background-color: #1a1a1a;
  display: flex;
  padding: 75px 50px;
  position: relative;

  @media only screen and (max-width: 793px) {
    flex-wrap: wrap;
  }
`;

const FooterContent = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  color: white;

  :first-child {
    justify-content: center;
  }

  p:last-child {
    margin-left: 15px;
  }

  a {
    color: white;
    font-family: 'Roboto';
    text-decoration: underline;
  }

  a:first-child {
    margin: 0 25px 0 75px;
  }

  @media only screen and (max-width: 793px) {
    width: 100%;
    flex-direction: column;
    align-items: center;

    p:last-child {
      margin: 15px 0 40px;
    }

    a:first-child {
      margin: 20px 0;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Heading type="footer" label="The Sound of Skinclusion" />
        <Heading type="subtitle" label="By Eucerin" />
      </FooterContent>

      <FooterContent>
        <Link to="/">Terms and conditions</Link>
        <Link to="/">Privacy statement</Link>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
