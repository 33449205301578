import React from 'react';
import styled from 'styled-components';

interface HeadingProps {
  type: string;
  label: string;
}

const Title = styled.p`
  font-size: 2.5rem;
  color: white;
  font-family: 'EucerinaWGL';
`;
const Subtitle = styled.p`
  font-size: 1.125rem;
  color: white;
`;
const Tooltitle = styled.p`
  font-size: 2rem;
  color: white;
`;
const Footer = styled.p`
  font-size: 1.5rem;
  color: white;
`;

const Heading = ({ type, label }: HeadingProps) => {
  const renderHeading = (headingType: string): React.ReactNode => {
    switch (headingType) {
      case 'title':
        return <Title>{label}</Title>;
      case 'subtitle':
        return <Subtitle>{label}</Subtitle>;
      case 'tool-title':
        return <Tooltitle>{label}</Tooltitle>;
      case 'footer':
        return <Footer>{label}</Footer>;
      default:
        return null;
    }
  };
  return <> {renderHeading(type)}</>;
};

export default Heading;
