import { FC } from 'react';
import styled from 'styled-components';

const MoreInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  row-gap: 0.5rem;
  color: white;
`;

const MoreInfo: FC<{ onClick?(): void }> = ({ onClick }) => {
  return (
    <MoreInfoContainer onClick={onClick}>
      <div>More info</div>
      <img src="/images/down-arrow.svg" alt="down arrow" />
    </MoreInfoContainer>
  );
};

export default MoreInfo;
