import ReactPlayer from 'react-player';
import { useInView } from 'react-hook-inview';
import Carousel from 'react-multi-carousel';
import styled, { keyframes, css } from 'styled-components';
import { fadeIn, fadeInUp } from 'react-animations';

import Navigation from 'components/Navigation';
import Heading from 'components/Heading';
import LinkButton from 'components/LinkButton';
import Footer from 'components/mobile/Footer';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: #a70532;
`;

const LandingContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  background: url('/img/landing_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  video {
    object-fit: cover;
    mix-blend-mode: screen;
  }
`;

const LandingContentContainer = styled.div`
  width: 100%;
  height: 50%;
  position: absolute;
  text-align: center;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  > div:first-child p:first-child {
    animation: 2s ${fadeInAnimation};
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
  }
  > div:first-child p:last-child {
    animation: 2s ${fadeInUpAnimation};
    animation-delay: 1.5s;
    animation-fill-mode: backwards;
    margin-bottom: 32px;
  }

  a {
    animation: 2s ${fadeInAnimation};
    animation-delay: 2.5s;
    animation-fill-mode: backwards;
  }

  > div:last-child {
    margin-top: 40px;
    animation: 1.5s ${fadeInAnimation};
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
  }
`;

const HowItWorksContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  display: flex;

  background-image: linear-gradient(0deg, #89bbe5 25%, #a70532 100%);

  > div:first-child {
    position: absolute;
  }

  video {
    object-fit: cover;
    mix-blend-mode: screen;
  }
`;

const HowItWorksContentContainer = styled.div<{ inView: boolean }>`
  width: 100%;
  height: 100%;
  padding: 80px 40px 80px 32px;

  .react-multi-carousel-list {
    height: 100%;
  }

  .react-multi-carousel-dot-list {
    right: auto;

    animation: ${({ inView }) => inView && css`1.5s ${fadeInAnimation}`};
    animation-delay: 1.5s;
    animation-fill-mode: backwards;
  }

  .react-multi-carousel-dot button {
    width: 32px;
    height: 3px;
    border: none;

    background: #ffffff;
    opacity: 0.4;
    border-radius: 1.5px;
  }

  .react-multi-carousel-dot--active button {
    opacity: 1;
  }
`;

const HowItWorksSlide = styled.div<{ inView?: boolean }>`
  width: 100%;
  height: calc(100vh - 160px);
  padding-bottom: 72px;

  display: flex;
  flex-direction: column;

  > div:first-child {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: ${({ inView }) => inView && css`1.5s ${fadeInAnimation}`};
    animation-delay: 1.5s;
    animation-fill-mode: backwards;
  }

  > div:last-child {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    p:not(:last-child) {
      animation: ${({ inView }) => inView && css`1.5s ${fadeInUpAnimation}`};
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
    }

    p:last-child {
      color: #fff;
      margin-top: 32px;

      animation: ${({ inView }) => inView && css`1s ${fadeInUpAnimation}`};
      animation-delay: 1s;
      animation-fill-mode: backwards;
    }
  }
`;

const OurMissionContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: #223341;

  :before {
    content: '';
    width: 100%;
    position: absolute;
    bottom: 40%;
    z-index: 2;
    box-shadow: 0px 0px 50px 50px #223341;
  }

  :after {
    content: '';
    width: 100%;
    height: 60%;
    position: absolute;
    top: 0;
    z-index: 1;

    background-image: url('/img/our_mission_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const OurMissionContentContainer = styled.div<{ inView: boolean }>`
  height: 100%;

  display: flex;
  align-items: flex-end;

  > div {
    padding: 80px 40px 80px 32px;
    margin-top: auto;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  > div p:not(:last-child) {
    animation: ${({ inView }) => inView && css`1.5s ${fadeInUpAnimation}`};
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
  }

  p:nth-child(3) {
    animation: ${({ inView }) => inView && css`1s ${fadeInUpAnimation}`};
    animation-delay: 1s;
    animation-fill-mode: backwards;
    margin: 32px 0 40px;
    color: #fff;
  }

  > div a {
    animation: ${({ inView }) => inView && css`2s ${fadeInAnimation}`};
    animation-delay: 1s;
    animation-fill-mode: backwards;
  }
`;

const Home = () => {
  const [howItWorksRef, howItWorksIsVisible] = useInView();
  const [ourMissionRef, ourMissionIsVisible] = useInView();

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container>
      <Navigation />

      <LandingContainer>
        <ReactPlayer
          width="100%"
          height="50%"
          url="/videos/Header_Black_H264_v01.mp4"
          playing
          muted
          loop
        />

        <LandingContentContainer>
          <div>
            <Heading type="subtitle" label="Eucerin presents" />
            <Heading type="title" label="The Sound of Skinclusion" />
          </div>
          <LinkButton buttontype="primary" to="/tool-start" label="Scan your skin" />
          <div
            role="button"
            tabIndex={0}
            aria-label="(un)mute volume"
            onClick={() => scrollTo('how-it-works')}
            onKeyDown={() => scrollTo('how-it-works')}
          >
            <img src="/images/down-arrow.svg" alt="down arrow" />
          </div>
        </LandingContentContainer>
      </LandingContainer>

      <HowItWorksContainer id="how-it-works">
        <ReactPlayer
          width="100%"
          height="90%"
          url="/videos/Slider_Black_H264_v01.mp4"
          playing
          muted
          loop
        />

        <HowItWorksContentContainer inView={howItWorksIsVisible}>
          <div ref={howItWorksRef} />
          <Carousel
            showDots
            arrows={false}
            ssr={false}
            infinite
            responsive={{
              desktop: { breakpoint: { max: 4000, min: 769 }, items: 1 },
              mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
            }}
          >
            <HowItWorksSlide inView={howItWorksIsVisible}>
              <div>
                <img src="/svg/audiowave.svg" alt="" />
              </div>
              <div>
                <Heading type="subtitle" label="How it works" />
                <Heading type="title" label="Make your skin heard" />
                <p>
                  Let our AI read the unique features of your facial skin as well as your current
                  visual mood and tone to create a unique song for you and the world to hear.
                </p>
              </div>
            </HowItWorksSlide>
            <HowItWorksSlide>
              <div>
                <img src="/svg/face.svg" alt="" />
              </div>
              <div>
                <Heading type="subtitle" label="How it works" />
                <Heading type="title" label="Your face is the beat" />
                <p>
                  We begin by mapping out your face. Your unique features and contours become the
                  base notes for the piece of music.
                </p>
              </div>
            </HowItWorksSlide>
            <HowItWorksSlide>
              <div>
                <img src="/svg/music.svg" alt="" />
              </div>
              <div>
                <Heading type="subtitle" label="How it works" />
                <Heading type="title" label="Your skin is the composer" />
                <p>
                  By scanning the surface of your skin, our AI selects the perfect instruments and
                  sound effects to complement your song.
                </p>
              </div>
            </HowItWorksSlide>
          </Carousel>
        </HowItWorksContentContainer>
      </HowItWorksContainer>

      <OurMissionContainer id="our-mission">
        <OurMissionContentContainer inView={ourMissionIsVisible}>
          <div ref={ourMissionRef}>
            <Heading type="subtitle" label="Our Social Mission" />
            <Heading type="title" label="#skinclusion" />
            <p>
              Visible skin conditions and diseases are more than just a physical burden. Being
              socially excluded can cause deep psychological and emotional scarring – scars that we
              can only heal if we all stand together against stigmatisation. Join us as we spread
              our message of #skinclusion.
            </p>
            <LinkButton buttontype="secondary" to="/transition" label="Find out more" />
          </div>
        </OurMissionContentContainer>
      </OurMissionContainer>

      <Footer />
    </Container>
  );
};

export default Home;
