// To-do: put this inside .env vars
const apiUrl = `https://saas.haut.ai/api/v1`;
const datasetId = 'a7a87561-d048-47de-bb9e-7e1a30f15514';
const username = 'info@monostatic.com';
const password = 'WcxLWhhTjVB7';

export const login = async () =>
  fetch(`${apiUrl}/login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  }).then(res => (res.ok ? res.json() : Promise.reject(res)));
// .catch(err => console.error(err));

export const createSubject = async (accessToken: string, companyId: string) =>
  fetch(`${apiUrl}/companies/${companyId}/datasets/${datasetId}/subjects/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ name: 'Eucerin Scanapp' }),
  }).then(res => (res.ok ? res.json() : Promise.reject(res)));
// .catch(err => console.error(err));

export const createBatch = async (accessToken: string, companyId: string, subjectId: string) =>
  fetch(`${apiUrl}/companies/${companyId}/datasets/${datasetId}/subjects/${subjectId}/batches/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(res => (res.ok ? res.json() : Promise.reject(res)));
// .catch(err => console.error(err));

export const uploadImage = async (
  accessToken: string,
  companyId: string,
  subjectId: string,
  batchId: string,
  b64data: string,
) =>
  fetch(
    `${apiUrl}/companies/${companyId}/datasets/${datasetId}/subjects/${subjectId}/batches/${batchId}/images/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ b64data }),
    },
  ).then(res => (res.ok ? res.json() : Promise.reject(res)));
// .catch(err => console.error(err));

export const getResultsForProcessedImage = async (
  accessToken: string,
  companyId: string,
  subjectId: string,
  batchId: string,
  imageId: string,
) =>
  fetch(
    `${apiUrl}/companies/${companyId}/datasets/${datasetId}/subjects/${subjectId}/batches/${batchId}/images/${imageId}/results/`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then(res => (res.ok ? res.json() : Promise.reject(res)));
// .catch(err => console.error(err));
