import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Heading from 'components/Heading';

const Container = styled.div`
  width: 100%;
  height: 35px;
  position: fixed;
  z-index: 2;
  margin-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:last-child {
    width: 76px;
  }

  @media only screen and (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 50px;

    > div:last-child {
      width: 24px;
    }
  }
`;

const StyledHamburgerContainer = styled.div`
  display: flex;
  cursor: pointer;
  z-index: 4;
  height: 24px;

  p {
    margin: -5px 0 0 10px;
  }

  @media only screen and (max-width: 768px) {
    p {
      display: none;
    }
  }
`;

const StyledOpenNavcontainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 51, 65, 50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(16px);

  > div {
    width: 100%;
    max-width: 1344px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media only screen and (max-width: 768px) {
    > div {
      height: 100%;
      flex-direction: column;
      align-items: center;
      padding: 150px 0 75px;
    }
  }
`;

const StyledNavListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  padding: 0 50px;

  li a {
    color: white;
    text-decoration: none;
    text-transform: capitalize;
  }

  :first-child {
    li:not(:first-child) {
      margin-top: 68px;
    }

    a {
      font-family: Recoleta;
      font-size: 56px;
      line-height: 60px;
    }
  }

  :last-child {
    li:not(:first-child) {
      margin-top: 34px;
    }

    a {
      font-family: Roboto;
      font-size: 15px;
      line-height: 24px;
      opacity: 0.5;
    }
  }

  @media only screen and (max-width: 768px) {
    align-items: center;

    :first-child {
      li:not(:first-child) {
        margin-top: 47px;
      }

      a {
        text-align: center;
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
`;

interface StyledProps {
  menuOpen: boolean;
}

const StyledHamburgerButton = styled.div<StyledProps>`
  position: relative;
  transform: translateY(6px);
  background: white;
  transition: all 0s;
  width: 24px;
  height: 2px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 8px;
    background: white;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    width: 24px;
    height: 2px;
  }

  &::after {
    width: 24px;
    height: 2px;
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    background: white;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  ${({ menuOpen }) =>
    menuOpen &&
    `
	background: rgba(0,0,0, 0);

	&::before {

		bottom: 0;
		transform: rotate(-45deg);
		transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
	}

	&::after {
		top: 0;
		transform: rotate(45deg);
		transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
	}
`}
`;

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <Container>
      <StyledHamburgerContainer onClick={() => setMenuOpen(!menuOpen)}>
        <StyledHamburgerButton menuOpen={menuOpen} />
        <Heading type="subtitle" label="Menu" />
      </StyledHamburgerContainer>

      {menuOpen && (
        <StyledOpenNavcontainer>
          <div>
            <StyledNavListContainer>
              <li>
                <Link to="/">The project</Link>
              </li>
              <li>
                <Link to="/something">Our philosophy</Link>
              </li>
              <li>
                <Link to="/something">Beautiful examples</Link>
              </li>
              <li>
                <Link to="/something">Eucerin.com</Link>
              </li>
            </StyledNavListContainer>
            <StyledNavListContainer>
              <li>
                <Link to="/">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/something">Privacy Statement</Link>
              </li>
              <li>
                <Link to="/something">Contact </Link>
              </li>
            </StyledNavListContainer>
          </div>
        </StyledOpenNavcontainer>
      )}

      <Link to="/">
        <img src="/images/logo.svg" alt="logo" />
      </Link>

      <div />
    </Container>
  );
};

export default Navigation;
