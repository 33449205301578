import { ReactElement, FC, useLayoutEffect, useState } from 'react';

type PropsType = {
  mobile: ReactElement;
  desktop: ReactElement;
};

const PageContainer: FC<PropsType> = ({ mobile, desktop }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useLayoutEffect(() => {
    const resize = () => (window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false));
    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return isMobile ? mobile : desktop;
};

export default PageContainer;
