import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';

import 'assets/euc_fonts/fonts.css';
import 'react-multi-carousel/lib/styles.css';

const GlobalStyle = createGlobalStyle`
html, body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

 h1 {
   font-family: 'EucerinaOT';
 }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
