import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import styled, { keyframes } from 'styled-components';
import { fadeInDown, fadeInUp, fadeIn } from 'react-animations';

import Heading from 'components/Heading';
import Button from 'components/LinkButton';
import Navigation from 'components/Navigation';

import useHash from 'hooks/useHash';

const fadeInDownAnimation = keyframes`${fadeInDown}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #89bbe5 0%, #a70532 100%);
`;

const ToolFinishContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > div {
    position: absolute;
  }

  video {
    object-fit: cover;
    mix-blend-mode: screen;
  }
`;

const ToolFinishContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div:first-child,
  > div:last-child {
    height: 25%;
  }

  > div:nth-child(2) {
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: 1.5s ${fadeInAnimation};
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    animation: 1.5s ${fadeInDownAnimation};
  }
`;

const BottomContainer = styled.div`
  padding: 0 56px;
  width: 100%;
  display: flex;
  flex-direction: column;

  animation: 1.5s ${fadeInUpAnimation};
`;

const ButtonRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  > a:first-child {
    margin-right: 14px;
  }
`;

const ToolStart = () => {
  const { pathname } = useLocation();
  const { decode } = useHash();
  const hash = pathname.split('/share/')[1];

  useEffect(() => {
    // const { id, timestamp } = decode(hash);
    // eslint-disable-next-line no-console
    // console.table({ id, timestamp });
  }, [decode, hash]);

  return (
    <Background>
      <ToolFinishContainer>
        <ReactPlayer
          width="100%"
          height="100%"
          url="/videos/Slider_Black_H264_v01.mp4"
          playing
          muted
          loop
        />
        <Navigation />

        <ToolFinishContentContainer>
          <div>
            <Heading label="Close your eyes" type="subtitle" />
            <Heading label="Listen to your skin" type="tool-title" />
          </div>

          <div>
            <img src="/svg/playbutton.svg" alt="" />
          </div>

          <BottomContainer>
            <ButtonRowContainer>
              <Button grow buttontype="primary" label="Save to your phone" />
              <Button buttontype="round" label={<img src="/images/save.svg" alt="" />} />
            </ButtonRowContainer>

            <Button grow buttontype="secondary" label="Create another unique track" />
          </BottomContainer>
        </ToolFinishContentContainer>
      </ToolFinishContainer>
    </Background>
  );
};

export default ToolStart;
